// ConfigContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from "react";
import { remoteConfig } from "@/services/firebase";
import { fetchAndActivate, getBoolean } from "firebase/remote-config";

// Set minimum fetch interval (e.g., 1 hour for production)
remoteConfig.settings = {
	minimumFetchIntervalMillis: 10000,
	fetchTimeoutMillis: 3000,
};

// Set default values for your Remote Config parameters
remoteConfig.defaultConfig = {
	feature_new_ui: true,
	// Add other default parameters here
};

// Define the shape of your config object
interface Config {
	ppt_addin_show_slide_library: boolean;
	ppt_addin_show_designer: boolean;
	ppt_addin_show_team: boolean;
	app_feature_termsheet: boolean;
	ppt_addin_auto_hide_bottom_bar: boolean;
	// Add other config parameters with their types
}

// Define the context value interface
interface ConfigContextValue {
	config: Config;
	loading: boolean;
	fetchConfig: () => Promise<void>;
}

// Create a context with default values
export const ConfigContext = createContext<ConfigContextValue>({
	config: remoteConfig.defaultConfig as unknown as Config,
	loading: true,
	fetchConfig: async () => {},
});

// Create a provider component
interface ConfigProviderProps {
	children: ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
	const [config, setConfig] = useState<Config>({
		ppt_addin_show_slide_library: remoteConfig.defaultConfig
			.ppt_addin_show_slide_library as boolean,
		ppt_addin_show_designer: remoteConfig.defaultConfig
			.ppt_addin_show_designer as boolean,
		ppt_addin_auto_hide_bottom_bar: remoteConfig.defaultConfig
			.ppt_addin_auto_hide_bottom_bar as boolean,
		ppt_addin_show_team: remoteConfig.defaultConfig
			.ppt_addin_show_team as boolean,
		app_feature_termsheet: remoteConfig.defaultConfig
			.app_feature_termsheet as boolean,
		// Initialize other parameters here
	});
	const [loading, setLoading] = useState<boolean>(true);

	const fetchConfig = async () => {
		try {
			const fetched = await fetchAndActivate(remoteConfig);
			const newConfig: Config = {
				ppt_addin_show_slide_library: getBoolean(
					remoteConfig,
					"ppt_addin_show_slide_library"
				),
				ppt_addin_show_designer: getBoolean(
					remoteConfig,
					"ppt_addin_show_designer"
				),
				ppt_addin_show_team: getBoolean(
					remoteConfig,
					"ppt_addin_show_team"
				),
				ppt_addin_auto_hide_bottom_bar: getBoolean(
					remoteConfig,
					"ppt_addin_auto_hide_bottom_bar"
				),
				app_feature_termsheet: getBoolean(
					remoteConfig,
					"app_feature_termsheet"
				),
				// Fetch other parameters here
			};
			console.log(
				"///////////////// setting remote config",
				newConfig,
				fetched,
				remoteConfig.lastFetchStatus
			);
			setConfig(newConfig);
		} catch (error) {
			console.error("Error fetching remote config:", error);
		}
	};

	useEffect(() => {
		let intervalId: NodeJS.Timeout;

		const startPeriodicFetch = () => {
			intervalId = setInterval(() => {
				fetchConfig();
			}, 3600000); // Fetch every 1 hour
		};

		fetchConfig().then(() => {
			setLoading(false);
			startPeriodicFetch();
		});

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<ConfigContext.Provider value={{ config, loading, fetchConfig }}>
			{children}
		</ConfigContext.Provider>
	);
};
